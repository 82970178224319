<template>
  <div class="widgetContainer shippingAddress">
    <div class="loginHeader">
      <img class="loginHeader__logo" :src="logoUrl" alt="logo">
      <div class="loginHeader__footer">
        <p class="cancel" @click="cancel">
          {{ $t('cancel') }}
        </p>
        <p class="description">
          {{ $t('card.text.replaceCard') }}
        </p>
        <p class="submit" :class="{ disabled: isFormValid }" @click="next()">
          {{ $t('next') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="personAddress"
        :model="formData"
        :rules="formRules">
        <div class="el-input--full-width el-input">
          <p class="wise-form__header">
            {{ $t('card_BillingAddress_HeaderTitle') }}
          </p>
          <el-form-item
            prop="address.line1"
            :rules="formRules.line1"
            class="is-no-asterisk"
            :label="$t('address_1')">
            <el-input style="display: none" />
            <el-input v-model="formData.address.line1" style="display: none" />
            <div class="el-input">
              <gmap-autocomplete
                :placeholder="$t('address_1')"
                autocomplete="disabled"
                :value="formData.address.line1"
                :options="{
                  componentRestrictions: { country: 'us' }
                }"
                @change.native="formData.address.line1 = $event.target.value"
                @place_changed="updateAddress($event)"
                :select-first-on-enter="true"
                class="el-input__inner" />
            </div>
          </el-form-item>
        </div>

        <el-form-item prop="address.line2" :label="$t('address_2')">
          <el-input
            :placeholder="$t('address_2')"
            v-model="formData.address.line2" />
        </el-form-item>
        <div class="wise-form__inline">
          <el-form-item
            prop="address.city"
            class="is-no-asterisk"
            :rules="formRules.city"
            :label="$t('address_City')">
            <el-input
              style="flex: 2"
              :placeholder="$t('address_City')"
              v-model="formData.address.city" />
          </el-form-item>
          <el-form-item
            prop="address.country"
            class="is-no-asterisk"
            :rules="formRules.state"
            :label="$t('address_State')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_State')"
              v-model="formData.address.state" />
          </el-form-item>
          <el-form-item
            prop="address.postalCode"
            class="is-no-asterisk"
            :rules="formRules.postalCode"
            :label="$t('address_Zipcode')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_Zipcode')"
              v-model="formData.address.postalCode" />
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { formatGoogleMapData } from '@/utils/gmap';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CardMixin from '../mixins/card';
export default {
  name: 'ContactAddressForm',
  mixins: [CardMixin],
  data() {
    return {
      formData: {
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          country: 'US',
          addressType: 'billing',
          state: null
        }
      },
      sameAddress: true,
      formRules: {
        line1: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        postalCode: [
          {
            required: true,
            message: 'Zip Code is required'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters('card', ['getReplaceCardData']),
    isFormValid() {
      if (
        this.formData.address.line1 &&
        this.formData.address.city &&
        this.formData.address.postalCode
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapMutations('card', ['updateReplaceCardDate']),
    ...mapActions('card', ['replaceCard']),
    updateAddress(e) {
      const address = formatGoogleMapData(e);
      this.formData.address = address;
    },
    next() {
      this.$refs.personAddress.validate((valid) => {
        if (!valid) return;

        /** @type {import('@/modules/card/store/state').cardState['replaceCardData']} */
        const card = {
          ...this.getReplaceCardData
        };
        const { line1, line2, city, postalCode, country, state } =
          this.formData.address;
        const payload = {
          ...card,
          shipping: {
            shippingAddress: {
              line1,
              line2,
              city,
              postalCode,
              country,
              addressType: 'billing',
              state
            }
          }
        };
        this.updateReplaceCardDate(payload);

      });
    },
    cancel() {
      this.$router.push('/card/cardManagement');
    }
  }
};
</script>
<style lang="scss" scoped></style>
